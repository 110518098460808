import axios from "axios";
// import storage from "./storage";
import { Loading } from "element-ui";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: "",
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 1000 * 60 * 20, // request timeout
});

let loadingInstance;

// request interceptor
service.interceptors.request.use(
  (config) => {
    // const language = storage.get('language');
    // console.log('lang: ' + language);
    // if(storage.get('language')){
    //   config.headers['language'] = language;
    // }
    loadingInstance = Loading.service();
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    // const res = response.data;
    // console.log(res);
    loadingInstance.close();
    console.log(response.data);
    return response.data;
  },
  (error) => {
    loadingInstance.close();
    return Promise.reject(error);
  }
);

export default service;
