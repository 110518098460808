<template>
  <div class="home">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      mode="horizontal"
      @select="handleSelect"
    >
      <el-menu-item index="1">Marketing</el-menu-item>
      <el-menu-item index="2">CRM</el-menu-item>
    </el-menu>
    <el-button @click="handleLogout()" class="logout">退出登录</el-button>
    <iframe :src="mauticUrl" frameborder="0" class="iframe" v-show="isShow == 1"></iframe>
    <iframe :src="crmUrl" frameborder="0" class="iframe" v-show="isShow == 2"></iframe>
  </div>
</template>

<script>
import storage from '@/utils/storage';
import HomePage from "../views/homePage.vue";

export default {
  name: HomePage,
  data() {
    return {
      isShow: 1,
      activeIndex: "1",
      userName: "",
      password: "",
      crmUrl: "",
      mauticUrl: "",
    };
  },
  created() {
    this.userName = storage.get('username');
    this.password = storage.get('password');
    this.mauticUrl = "https://mautic.sui1.net/s/login?" + this.userName + "&" + this.password;
  },
  mounted() {
    if(storage.get('loginStatus') == null) {
      this.$router.push({
        path: '/loginPage'
      });
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      switch(key) {
        case "1": {
          this.isShow = 1;
          this.mauticUrl = "https://mautic.sui1.net/s/login?" + this.userName + "&" + this.password;
          break;
        }
        case "2": {
          this.isShow = 2;
          this.crmUrl = "https://crm.sui1.net/admin/authentication?" + this.userName + "&" + this.password;
          break;
        }
      }
    },
    handleLogout() {
      this.$confirm('请确认是否退出登录?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          storage.remove("loginStatus");
          storage.remove("username");
          storage.remove("password");
          this.$router.push({
            path: "/loginPage"
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退出登录'
          });          
        });
    }
  },
};
</script>

<style lang="less" scoped>
.logout {
  position: absolute;
  top: 10px;
  right: 10px;
}

.iframe {
  width: 100%;
  height: 90vh;
}

</style>
