<template>
  <div class="login">
    <img src="../assets/images/INOVA4.png" alt="" />
    <p>Login</p>
    <el-form
      :model="infoForm"
      status-icon
      :rules="infoRules"
      ref="infoForm"
      label-position="left"
      class="form"
    >
      <el-form-item label="用户名：" prop="userName">
        <el-input v-model="infoForm.userName"></el-input>
      </el-form-item>
      <el-form-item label="密码：" prop="password">
        <el-input type="password" v-model="infoForm.password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="handleLogin()"
          @keyup.enter="keyDown(e)"
          :disabled="clicked"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import LoginPage from "../views/loginPage";
import { mauticLogin, crmLogin } from "../api/register";
import storage from "../utils/storage";
import debounce from "lodash/debounce";

export default {
  name: LoginPage,
  data() {
    var validateUserName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      } else {
        // setTimeout(() => {
        //   if (this.infoForm.userName !== "") {
        //     this.$refs.infoForm.validateField("userName");
        //   }
        // });
        callback();
      }
    };
    var validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        // setTimeout(() => {
        //   if (this.infoForm.password !== "") {
        //     this.$refs.infoForm.validateField("password");
        //   }
        // });
        callback();
      }
    };
    return {
      mauticStatus: false,
      crmStatus: false,
      infoForm: {
        userName: "",
        password: "",
      },
      clicked: false,
      infoRules: {
        userName: [
          // 用户名不能为空
          {
            required: true,
            trigger: "blur",
            validator: validateUserName,
          },
        ],
        password: [
          // 密码不能为空
          {
            required: true,
            trigger: "blur",
            validator: validatePassword,
          },
        ],
      },
    };
  },
  created() {},
  mounted() {
    storage.remove("username");
    storage.remove("password");
    // 绑定监听事件
    window.addEventListener("keydown", this.keyDown);
  },
  methods: {
    // 使用防抖函数包装登录事件
    debouncedLogin: debounce(function () {
      // 在这里执行登录操作
      this.handleLogin();
    }, 500), // 500毫秒的防抖延迟
    // 登录点击事件
    handleLogin() {
      this.clicked = true;
      this.$refs["infoForm"].validate((valid) => {
        if (valid) {
          mauticLogin({
            username: this.infoForm.userName,
            password: this.infoForm.password,
          }).then((res1) => {
            console.log(res1);
            this.mauticStatus = res1.success;
            if (res1.success) {
              crmLogin({
                username: this.infoForm.userName,
                password: this.infoForm.password,
              }).then((res2) => {
                console.log(res2);
                this.crmStatus = res2.success;
                if (res2.success) {
                  storage.set("loginStatus", true);
                  storage.set("username", this.infoForm.userName);
                  storage.set("password", this.infoForm.password);
                  this.$router.push({
                    path: "/homePage",
                  });
                } else {
                  this.clicked = false;
                  this.$message.error('账号或密码错误');
                }
              });
            } else {
              this.clicked = false;
              this.$message.error('账号或密码错误');
            }
          });
        } else {
          this.clicked = false;
          this.$message.error('请先填写信息');
        }
      });
    },
    keyDown(e) {
      if (e.key == "Enter") {
        this.debouncedLogin();
        e.preventDefault(); // 去掉默认的换行
      }
    },
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener("keydown", this.keyDown, false);
  },
};
</script>

<style lang="less" scoped>
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin: 25vh auto 0;

  .form {
    width: 100%;
    padding: 30px;
    border-radius: 10px;
    background-color: #fff;

    .el-form-item {
      display: flex;
      flex-direction: column;

      .el-button {
        width: 100%;
        margin-top: 20px;
        background-color: #2563eb;
      }
    }
  }

  img {
    width: 360px;
    height: auto;
  }

  p {
    font-size: 28px;
    font-weight: 700;
  }
}
</style>
