import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import storage from './utils/storage'

Vue.config.productionTip = false

Vue.use(ElementUI,{
  size: 'medium'
})

new Vue({
  router,
  storage,
  render: h => h(App),
}).$mount('#app')
