import request from "@/utils/request";

// mautic的API接口
export function mauticLogin(data) {
  return request({
    url: "/mautic/passwordcheck",
    method: "post",
    data
  });
}

// crm的API接口
export function crmLogin(data) {
  return request({
    url: "/crm/api/check_pass",
    method: "post",
    data
  });
}
