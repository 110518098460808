import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/loginPage";
import HomePage from "../views/homePage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/loginPage",
  },
  {
    path: "/loginPage",
    name: "loginPage",
    component: LoginPage,
  },
  {
    path: "/homePage",
    name: "homePage",
    component: HomePage,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
